<template>
  <div class="fleetDashboard" :class="'fleetDashboard--' + dashboardType">
    <div v-if="dashboardType === 'provider'" class="bookingDashboard bookingDashboard--list">
      <div class="bookingDashboard__header bookingDashboard__header--provider">
        <div class="bookingDashboard__title">Fleet list</div>
        <router-link class="buttonCreate" to="new-aircraft">Create</router-link>
      </div>
      <div class="bookingDashboard__table">
        <div class="bookingDashboard__table-header">
          <div class="bookingDashboard__table-cell">Tail number</div>
          <div class="bookingDashboard__table-cell">Model</div>
          <div class="bookingDashboard__table-cell">Type</div>
        </div>
        <div class="bookingDashboard__table-body">
          <vue-scroll :ops="ops">
            <div
              class="bookingDashboard__table-row"
              v-for="item of fleet"
              :key="item.aircraftId"
            >
              <div class="bookingDashboard__table-cell aircraftNumber">{{item.tailNumber}}</div>
              <div class="bookingDashboard__table-cell aircraftModel">{{item.modelName}}</div>
              <div class="bookingDashboard__table-cell aircraftType">
                {{item.typeName}}
                <router-link class="bookingDashboard__table-detail" :to="'fleet/'+ item.aircraftId">click for details</router-link>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
<!--        <div class="fleetDashboard__inner">-->
<!--          <router-link class="fleetDashboard__button" to="/provider-dashboard/new-aircraft">-->
<!--            <span class="text">Add new aircraft</span>-->
<!--          </router-link>-->
<!--          <div class="fleetDashboard__subtitle">Fleet</div>-->
<!--          <div v-if="!fleet.length" class="fleetDashboard__empty js-aircraft-empty">no aircraft</div>-->
<!--          <div class="fleetDashboard__list">-->
<!--            <div-->
<!--              v-for="item of fleet"-->
<!--              :key="item.id"-->
<!--              class="fleetDashboard__list-item js-aircraft-item"-->
<!--            >-->
<!--              <router-link-->
<!--                :to="'/provider-dashboard/fleet/' + item.id"-->
<!--                class="fleetDashboard__list-item-title"-->
<!--              >-->
<!--                <b>Aircraft {{item.counter}}: </b>{{item.title}}-->
<!--              </router-link>-->
<!--              <div class="fleetDashboard__list-item-remove js-remove-aircraft" @click="showModal(item.id)">-->
<!--                <SvgIcon name="close"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
    <vue-scroll :ops="ops"  v-if="dashboardType === 'admin'">
      <div>
        <div class="fleetDashboard__subtitle">Log of all uploaded aircraft in system</div>
        <div class="fleetDashboard__nav">
          <div class="fleetDashboard__nav-item js-tabNavItem" @click="tabActivate">
            <span class="text">Current</span>
          </div>
          <div class="fleetDashboard__nav-item js-tabNavItem" @click="tabActivate">
            <span class="text">Historical</span>
          </div>
        </div>
        <div class="fleetDashboard__content">
          <div class="fleetDashboard__content-item js-tabContentItem">
            <div class="fleetDashboard__inner">
              <div class="fleetDashboard__subtitle">Aircraft:</div>
              <div v-if="!fleet.length" class="fleetDashboard__empty js-aircraft-empty">no aircraft</div>
              <div class="fleetDashboard__list">
                <div
                  v-for="item of fleet"
                  :key="item.id"
                  class="fleetDashboard__list-item js-aircraft-item"
                >
                  <router-link
                    :to="'/admin/aircraft-uploads/' + item.id"
                    class="fleetDashboard__list-item-title"
                  >
                    <b>Aircraft {{item.counter}}: </b>{{item.title}}
                  </router-link>
                  <div class="fleetDashboard__list-item-remove js-remove-aircraft" @click="showModal(item.id)">
                    <SvgIcon name="close"/>
                  </div>
                </div>
              </div>
              <router-link class="fleetDashboard__button" to="/admin/new-aircraft">
                <span class="text">Add new aircraft</span>
              </router-link>
            </div>
          </div>
          <div class="fleetDashboard__content-item js-tabContentItem">
            <div class="fleetDashboard__inner">
              <div class="fleetDashboard__subtitle">Aircraft:</div>
              <div v-if="!fleet.length" class="fleetDashboard__empty js-aircraft-empty">no aircraft</div>
              <div class="fleetDashboard__list">
                <div
                  v-for="item of fleet"
                  :key="item.id"
                  class="fleetDashboard__list-item js-aircraft-item"
                >
                  <router-link
                    :to="'/admin/aircraft-uploads/' + item.id"
                    class="fleetDashboard__list-item-title"
                  >
                    <b>Aircraft {{item.counter}}: </b>{{item.title}}
                  </router-link>
                  <div class="fleetDashboard__list-item-remove js-remove-aircraft" @click="showModal(item.id)">
                    <SvgIcon name="close"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <QuestionPopup
        :name="'questionPopupFleet'"
        :title="'The aircraft will be removed'"
        :text="'Are you sure?'"
        @remove="remove"
      />
    </vue-scroll>
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import './Fleet.styl'

  export default {
    props: ['dashboardType'],
    name: 'Fleet',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      fleet () {
        return this.$store.state.fleet.list
      },
    },
    async created () {
      await this.$store.dispatch('getAircrafts')
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      showModal (id) {
        this.$modal.show('questionPopupFleet')
        this.$store.commit('insertCurrentFleet', id)
      },
      remove () {
        this.$store.commit('removeItemFleet')
      },
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
    },
    components: {
      SvgIcon,
      QuestionPopup,
    },
  }
</script>
